// $body-font-family: "Inconsolata";

$header-height: 5rem !default;

// Colours
$positive-100: #DDF8F7 !default;
$positive-400: #00B8B1 !default;
$positive-800: #007571 !default;

$warning-100: #FFF6EB !default;
$warning-400: #E79637 !default;
$warning-800: #A46214 !default;