.feedback-module {
  background-color: #E8EDF2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

.feedback-module-title {
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
}

.feedback-module-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}

.feedback-module-button:hover {
  text-decoration: underline;
}

.feedback-module-button p {
  font-size: 12px;
  color: #2B3645;
}

// Space between logo and nav items
.site-nav {
  padding-top: 2rem;
}

// Set nested nav items' color to purple
.nav-list .nav-list-item > .nav-list .nav-list-item .nav-list-link {
  color: #7253ed;
}

// Increase the size of the first nav item (Introduction)
.site-nav > .nav-list:first-child > .nav-list-item {
  font-size: 24px !important;
}

// Fix nav category size and color
.nav-category {
  font-size: 16px !important;
  color: #000;
}

// Sidebar color and font boldness
.side-bar {
  background-color: #E8EDF2;
  font-weight: 600;
}

/*
 * The following overrides several behabiours for the sidebar to allow for:
 * - 2rem padding on either sideof the sidebar
 * - increased size to allow for padding
 * - responsive resizing based on new sizing
 * - Override at 50 and 66.5 rem
 */
@media (min-width: 50rem) {
  .side-bar {
      flex-flow: column nowrap;
      position: fixed;
      width: 28.5rem;
      height: 100%;
      border-right: 1px solid #eeebee;
      align-items: flex-end;
      padding-top: 2rem;
  }

  .site-nav {
    width: 30.5rem;
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .main {
    position: relative;
    max-width: 50rem;
    margin-left: 28.5rem;
  }
}

@media (min-width: 66.5rem) {
  .side-bar {
    width: 28.5rem;
  }

  .main {
    margin-left: 28.5rem;
  }
}

@media (min-width: 100.5rem) {
  .side-bar {
      width: calc((100% - 100.5rem) / 2 + 28.5rem);
      min-width: 28.5rem;
  }

  .main {
    margin-left: max(28.5rem,(100% - 100.5rem) / 2 + 28.5rem);
  }
}


// Fix mobile responsiveness with logo svg
.site-header {
  height: auto;
  max-height: none;
  flex-direction: row;
  justify-content: space-between;

  // Keep logo in-line with nav items for big screens
  @media (min-width: 50rem) {
    width: 28.5rem;
    padding-right: 4rem;
    padding-left: 4rem;
  }

  // Logo sizing and padding for small and larger screens
  > a {
    max-width: 14.5rem;

    > img {
      height: 2.5rem;
      margin-left: 1rem;
    }

    @media (min-width: 50rem) {
      max-width: auto;

      > img {
        height: auto;
        margin-left: 0;
      }
    }
  }
}

// Padding on left of main content
.main-content-wrap {
  @media (min-width: 50rem) {
    padding-left: 4rem;
  }
}

body {
  font-family: 'Inter';
}

// Labels (not the form kind)

// Labels
.label-green:not(g) {
  color: $positive-800;
  background-color: $positive-100;
  border: 1px solid $positive-400;
}

.label-yellow:not(g) {
  color: $warning-800;
  background-color: $warning-100;
  border: 1px solid $warning-400;
}